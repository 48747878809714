import React, { Suspense, lazy, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { NavHashLink as Link } from "react-router-hash-link";
import { Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Services from "./pages/Services";
import theme from "./theme";
import Gallery from "./pages/Galleria";
import useGlobal from "./hooks/store";

const Home = lazy(() => import("./pages/Home"));
const Info = lazy(() => import("./pages/Info"));
const Table = lazy(() => import("./pages/Table"));
const Reservation = lazy(() => import("./pages/Reservation"));
const Contact = lazy(() => import("./pages/Contact"));
const Tietosuoja = lazy(() => import("./pages/Tietosuoja"));
const Footer = lazy(() => import("./components/Footer"));
const Header = lazy(() => import("./components/Header"));

function App() {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    if (
      globalState.frontPage.prices.length === 0 &&
      globalState.frontPage.openingHours.length === 0
    ) {
      globalActions.getFrontPage();
    }
  }, [
    globalActions,
    globalState.frontPage.openingHours,
    globalState.frontPage.prices,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />

        <Switch>
          <Route exact path={["/", "/#home"]}>
            <Home id="home" />

            <Info id="info" hours={globalState.frontPage.openingHours} />

            <Table id="hinnasto" prices={globalState.frontPage.prices} />

            <Reservation id="ajanvaraus" />
          </Route>
          <Route path="/palvelut">
            <Services />
          </Route>
          <Route path="/yhteys">
            <Contact />
          </Route>
          <Route path="/galleria">
            <Gallery />
          </Route>
          <Route path="/tietosuoja">
            <Tietosuoja />
          </Route>
        </Switch>

        <Footer />
      </Suspense>
      <CookieConsent
        location="bottom"
        buttonText="OK"
        sameSite="strict"
        cookieName="cookieConsent"
        style={{ background: "#cacaca", color: "#000" }}
        buttonStyle={{ background: "#5a1c00", color: "#fff", fontSize: "13px" }}
        expires={150}
      >
        Ikobana-sivusto käyttää kolmansien osapuolien palveluiden Facebook ja
        Google Analytics tarjoamia evästeitä. <br />
        Klikkaamalla OK hyväksyt evästeiden käytön.{" "}
        <span style={{ fontSize: "10px" }}>
          <Link to="/tietosuoja">Tietosuojaseloste </Link>
        </span>
      </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
