import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import { Flex } from "rebass";

const Header = styled.h2`
  font-size: 25px;
  color: ${({ theme, reverse }) =>
    reverse ? theme.colors.white : theme.colors.main};
  text-align: center;
  margin: 0;
  padding: 0;
`;

function Title({ reverse, height, pt, children }) {
  const themeContext = useContext(ThemeContext);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg={reverse ? themeContext.colors.main : themeContext.colors.grey}
      pt={pt}
      sx={{ lineHeight: "24vh", height: height }}
    >
      <Header reverse={reverse}>{children}</Header>
    </Flex>
  );
}
Title.defaultProps = {
  reverse: false,
};
export default Title;
