import media from "./mediaQuery";

export default {
  breakpoints: ["360px", "375px", "768px", "1366px", "1920px"],
  media,
  colors: {
    main: "#5a1c00",
    secondary: "#ede7e4",
    black: "#0b0500",
    white: "#FFFFFF",
    grey: "#f9f9f9",
  },
};
