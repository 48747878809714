import React, { useEffect, useState } from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Gallery from "react-photo-gallery";
import Title from '../components/Title';
import { Box } from 'rebass';
import useGlobal from '../hooks/store';

function Galleria() {
  const [globalState, globalActions] = useGlobal();
  const [sources, setSources] = useState([]);

  useEffect(() => {
    const getPhotos = (gallery) => {
      let src = [];
      gallery.map((img) =>
        src.push({
          src: img.fields.file.url,
          width: img.fields.file.details.image.width,
          height: img.fields.file.details.image.height,
        })
      );
      setSources(src);
    };

    if (globalState.gallery.length === 0) {
      globalActions.getImages();
    }

    if (globalState.gallery) getPhotos(globalState.gallery);
  }, [globalActions, globalState.gallery]);

  const options = {
    settings: {
      overlayColor: "#5a1c00",
    },
    buttons: {
      backgroundColor: "#ede7e4",
      iconColor: "black",
    },
  };

  return (
    <SimpleReactLightbox>
    <Box pt='80px'>
      <Title reverse height='200px'>
        GALLERIA
      </Title>

      <>
      <SRLWrapper options={options}>
        <Gallery photos={sources} />
      </SRLWrapper>

      </>
    </Box>
    </SimpleReactLightbox>
  );
}

export default Galleria;
