import React from 'react';
import useGlobalHook from './useGlobalHook';

import * as actions from '../actions/serviceLayer';

const initialState = {
  frontPage: {
    prices: [],
    openingHours: [],
  },

  services: [],
  aboutMe: '',
  status: 'INIT',
  gallery: [],
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
