import axios from 'axios';
import * as contentful from 'contentful';

const AuthStr = `Bearer ${process.env.REACT_APP_API_KEY}`;

const client = contentful.createClient({
  space: '2fbkcfghjesn',
  // environment: '<environment_id>', // defaults to 'master' if not set
  accessToken: process.env.REACT_APP_API_KEY
});

export const getFrontPage = (store) => {
  client
  .getEntry('3mwLaBmKxrtOXlK9w1apGw')
  .then(entry => {
    const frontPage = entry.fields;
    const status = 'SUCCESS'
    store.setState({ frontPage, status });
  })
    .catch((error) => {
      console.log('error ' + error);
      const status = 'fail';
      store.setState({ status });
    });
}

// export const getFrontPage = async (store) => {
//   const status = 'LOADING';
//   store.setState({ status });
//   const URL =
//     'https://cdn.contentful.com/spaces/2fbkcfghjesn/entries/3mwLaBmKxrtOXlK9w1apGw';

//   axios
//     .get(URL, { headers: { Authorization: AuthStr } })
//     .then((response) => {
//       const frontPage = response.data.fields;
//       const status = 'SUCCESS';

//       store.setState({ frontPage, status });
//     })
//     .catch((error) => {
//       console.log('error ' + error);
//       const status = 'fail';
//       store.setState({ status });
//     });
// };

export const getServices = async (store) => {
  client
  .getEntry('55HQ6UoZdwAUACR9OAhAHN')
  .then(entry => {
    const { services } = entry.fields;
    const status = 'SUCCESS'
    store.setState({ services, status });
  })
    .catch((error) => {
      console.log('error ' + error);
      const status = 'fail';
      store.setState({ status });
    });
}

// export const getServices = async (store) => {
//   const status = 'LOADING';
//   store.setState({ status });
//   const URL =
//     'https://cdn.contentful.com/spaces/2fbkcfghjesn/entries/55HQ6UoZdwAUACR9OAhAHN';

//   axios
//     .get(URL, { headers: { Authorization: AuthStr } })
//     .then((response) => {
//       const { services } = response.data.fields;

//       const status = 'SUCCESS';

//       store.setState({ services, status });
//     })
//     .catch((error) => {
//       console.log('error ' + error);
//       const status = 'fail';
//       store.setState({ status });
//     });
// };

export const getAboutMe = async (store) => {
  client
  .getEntry('3mlllOMdcfDhIxOuU2s1lG')
  .then(entry => {
    const { aboutMe } = entry.fields;
    const status = 'SUCCESS'
    store.setState({ aboutMe, status });
  })
    .catch((error) => {
      console.log('error ' + error);
      const status = 'fail';
      store.setState({ status });
    });
}

// export const getAboutMe = async (store) => {
//   const status = 'LOADING';
//   store.setState({ status });
//   const URL =
//     'https://cdn.contentful.com/spaces/2fbkcfghjesn/entries/3mlllOMdcfDhIxOuU2s1lG';

//   axios
//     .get(URL, { headers: { Authorization: AuthStr } })
//     .then((response) => {
//       const { aboutMe } = response.data.fields;
//       const status = 'SUCCESS';
//       store.setState({ aboutMe, status });
//     })
//     .catch((error) => {
//       console.log('error ' + error);
//       const status = 'fail';
//       store.setState({ status });
//     });
// };


export const getImages = async (store) => {
  const status = 'LOADING';
  store.setState({ status });
  const URL = 'https://cdn.contentful.com/spaces/2fbkcfghjesn/assets';

  axios
    .get(URL, { headers: { Authorization: AuthStr } })
    .then((response) => {
      const { items } = response.data;
      const status = 'SUCCESS';
      store.setState({ gallery: items, status });
    })
    .catch((error) => {
      console.log('error ' + error);
      const status = 'fail';
      store.setState({ status });
    });
};
