import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Title from '../components/Title';
import { Box, Flex } from 'rebass';
// import services from '../services';

import useGlobal from '../hooks/store';

const Header = styled.h2`
  font-size: ${({ footnote }) => (footnote ? '15px' : '20px')};
  color: ${({ theme, footnote }) => (footnote ? '#000' : theme.colors.main)};
  text-decoration: underline;
  align-self: ${({ footnote }) => (footnote ? 'flex-start' : null)};
`;

function Services() {
  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };

  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    if (globalState.services.length === 0) {
      globalActions.getServices();
    }
  }, [globalActions, globalState.services]);

  const { services } = globalState;

  const data =
    services.length > 0
      ? services.map((service, i) => (
          <Fragment key={`${service.section}_${i}`}>
            <Header footnote={service.footnote}>{service.section}</Header>
            <Box mb='50px'>
              {service.items.map((item, i) => (
                <Fragment key={item.name + i}>
                  <p>
                    {item.name && (
                      <span>
                        <b>{item.name}: </b>
                      </span>
                    )}
                    {item.desc && (
                      <span
                        style={{ fontSize: service.footnote ? '12px' : '16px' }}
                      >
                        {item.desc}
                      </span>
                    )}
                  </p>
                </Fragment>
              ))}
            </Box>
          </Fragment>
        ))
      : 'Loading';

  return (
    <>
      <ScrollToTopOnMount />
      <Box pt='80px'>
        <Title reverse height='200px'>
          PALVELUT
        </Title>
        <Flex
          flexDirection='column'
          alignItems='center'
          m='90px auto'
          width={[1, '70%', '60%', '40%']}
          p={['40px', 0]}
          sx={{ whiteSpace: 'pre-line' }}
        >
          {data}
        </Flex>
      </Box>
    </>
  );
}

export default Services;
